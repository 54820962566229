import * as React from "react"

import Layout from "../components/layout"
import Properties from "../components/properties"
import Seo from "../components/seo"
import Services from "../components/services"
import Hero from "../components/hero"
import Blog from "../components/blog"
import Contact from "../components/contact"
import { graphql, useStaticQuery } from "gatsby"

const seo = {
  metaTitle: 'Home',
}

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      strapiHomepage {
        Diensten {
          icoon
          titel
          description
        }
      }
    }
  `)
  
  const services = data.strapiHomepage.Diensten.map(d => {
    return {
      icon: d.icoon,
      title: d.titel,
      description: d.description
    };
  });

  return (
    <Layout active="home" page="home">
      <Seo seo={seo} />
      <Hero />
      <Contact />
      <Properties title="Aanbod" types={['sale', 'rent']} items={3} />
      <Services clickable={true} title="Diensten" services={services} />
      <Blog title="Blog" items={3} />
    </Layout>
  )
    }

export default IndexPage
