import { graphql, useStaticQuery } from "gatsby";
import * as React from "react"

const Contact = () => {

  const data = useStaticQuery(graphql`
    query {
      strapiContact {
        email
        facebook
        phone
        address_street
        address_city
      }
    }
  `);

  return (
    <div className="contact-bar" style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: -20, paddingBottom: 20 }}>
      <div className="calling-info" style={{ paddingBottom: 40 }}> 
        <span><i className="fa fa-map-o"></i>Vind ons</span>
        <a rel="noreferrer" target="_blank" href={'https://www.google.com/maps/place/' + data.strapiContact.address_street + ',' + data.strapiContact.address_city}>{data.strapiContact.address_street}, {data.strapiContact.address_city}</a>
      </div>
      <div className="calling-info" style={{ paddingBottom: 20 }}> 
        <span><i className="fa fa-phone"></i>Bel ons</span>
        <a href={'tel:' + (data.strapiContact.phone || '')}>{data.strapiContact.phone}</a>
      </div>
      <div className="calling-info" style={{ paddingBottom: 20 }}> 
        <span><i className="fa fa-envelope-o"></i>Mail ons</span>
        <a href={'mailto:' + (data.strapiContact.email || '')}>{data.strapiContact.email}</a>
      </div>
      <div className="calling-info" style={{ paddingBottom: 20 }}> 
        <span><i className="fa fa-facebook"></i>Volg ons</span>
        <a rel="noreferrer" target="_blank" href={(data.strapiContact.facebook || '')}>Facebook</a>
      </div>
    </div>
  )
}

export default Contact