/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { graphql, useStaticQuery } from "gatsby";
import Image from "./image";
 
const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      strapiHomepage {
        hero {
          title
          subtitle
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920, height: 540)
              }
            }
          }
        }
      }
    }
  `);

  return (
    <section> 
      <div className="featured-slider">
        <div className="slider-content">
          <div className="slider-image">
            <Image 
              image={data.strapiHomepage.hero.image.localFile.childImageSharp.gatsbyImageData}
              alt={`Afbeelding van ${data.strapiHomepage.hero.title}`}
              sizes={'100vw'}
              loading="eager" />
          </div>
        </div>
      </div>
      <div className="slider-text">
        <div className="slider-text-image">
          <img alt="Lisimmo" width="58" height="48" src="/images/logo.png" />
        </div>
        <h1 style={{ display: 'none' }} className="slider-title">{data.strapiHomepage.hero.title}</h1>
        {data.strapiHomepage.hero.subtitle && (<p>{data.strapiHomepage.hero.subtitle}</p>)}
      </div>
    </section>
  )
}
 
Hero.propTypes = {
}

export default Hero

