import * as React from "react"
import { graphql, Link, useStaticQuery } from "gatsby";
import PropTypes from "prop-types"
import Image from "./image";
 
const Blog = ({ title, items }) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiArticle {
        edges {
          node {
            title
            description
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 500, height: 300)
                }
              }
            }
            slug
          }
        }
      }
    }
  `);

  return (
    <section className="blog-section featured-section"> 
      <div className="container">
        <header className="entry-header heading">
          <h2 className="entry-title">{title}</h2>
        </header> 
        <div className="row">
          {data.allStrapiArticle.edges.slice(0, items).map(item => (
            <Link key={item.node.slug} to={'/blog/'+item.node.slug} className="custom-col-4" style={{ display: 'block' }}>
              <article className="post">
                <figure className="post-featured-image">
                  <Image
                      image={item.node.image.localFile.childImageSharp.gatsbyImageData}
                      alt={`Afbeelding van ${item.title}`}
                      sizes={'(min-width: 1200px) 360px, (min-width: 768px) 33vw, 50vw'}
                    />
                </figure>
                <header className="entry-header">
                  <h3 className="entry-title">{item.node.title}</h3>
                </header>
                <div className="entry-content">
                  {item.node.description}
                </div>
              </article>
            </Link>
          ))}
        </div>
        {items < 10 && (<div className="row">
          <div className="custom-col-4" style={{ textAlign: "center" }}>
            <Link to="/blog" className="box-button">Meer artikels</Link>
          </div>
        </div>)}
      </div>
    </section>
  );
}

Blog.propTypes = {
	title: PropTypes.string.isRequired,
  items: PropTypes.number.isRequired
}

Blog.defaultProps = {
  title: `Blog`,
  items: 999
}

export default Blog
